/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { message, Modal } from 'antd';

import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from '../../../styled/publicTag.js';

import OperateArea from '../../../components/Custom/OperateArea';

import { DataContext } from '../index';

import { getAllSophd, deleteSophd } from "../../../services/apis/sopAPI";

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';
import PrintModalContainer from './PrintModalContainer';
import moment from 'moment'

const innerWidth = window.innerWidth;

const HD = () => {

  const [scrollY, setScrollY] = useState("")
  
  const [ s_showModal, set_s_showModal ] = useState(false);
  const [ s_printModal, set_s_printModal ] = useState(false);

  const [ s_printData, set_s_printData ] = useState(false);

  const [ s_editData, set_s_editData ] = useState({});

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 搜尋途程標準管理資料
  const onSearch = (value, event) => {

    const call_getAllSophd = async (value) => {

      if (value === '') return;

      const res = await getAllSophd({ PN: value });

      if (res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        set_s_tableData([]);
        message.error(res.msg);
      }

    }
    call_getAllSophd(value);
  }

  // 刪除搜尋文字時重新抓資料
  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if (value === '') {
      try {
        const res = await getAllSophd();
        console.log('res = ', res)
        if (res.data) {
          console.log('全部途程標準管理資料 = ', res.data);
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          });
          set_s_tableData(tmpData)
        }

      } catch (e) {
        console.log('---------- api getAllSophd error ----------');
        console.log(e)
      }
    }
  }

  // 新增途程標準管理資料
  const createStandard = () => {
    set_s_showModal(true);
  }

  // 編輯途程標準管理資料
  const editSOPHd = rowData => {
    set_s_showModal(true);
    set_s_editData(rowData);
    set_c_lightData(rowData);
  }

  // 取得所有途程標準管理資料
  const call_getAllSophd = async () => {
    try {
      const res = s_searchValue === '' ? await getAllSophd() : await getAllSophd({ item: s_searchValue });
      console.log('??? = ', res)
      if (res.status) {
        console.log('全部製程標準資料(表頭) = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } else {
        set_s_tableData([])
      }

    } catch (e) {
      console.log('---------- api getAllSophd error ----------');
      console.log(e)
    }
  }

  // 刪除途程標準管理資料
  const removeSophd = rowData => {
    Modal.confirm({
      title: '製程標準資料刪除確認',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: '請先確認要刪除的製程標準資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteSophd(rowData);
        console.log('res = ', res)
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllSophd();
        set_c_lightData({})
      },
      cancelText: '取消',
    });
  }

  const print = (rowData) => {
    set_s_printModal(true);
    set_s_printData(rowData)
  }

  const tableColumns = [
    {
      title: "工位文編",
      dataIndex: "sopID",
      key: "sopID",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${220}px`
    },
    {
      title: "版碼",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      align: "center",
      width: innerWidth <= 1440 ? `${120}px` : `${200}px`,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      align: "left",
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
    },
    {
      title: "總工位數",
      dataIndex: "sopno",
      key: "sopno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "組裝序",
      dataIndex: "assyno",
      key: "assyno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "檢測序",
      dataIndex: "inspno",
      key: "inspno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "包裝序",
      dataIndex: "packno",
      key: "packno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${200}px`,
    },
    {
      title: "是否有效",
      dataIndex: "isvalid",
      key: "isvalid",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      render: (item) => {
        return (
          <div className="checkIcon">
            {item === false ? <CloseCircleOutlined style={{color: 'gray'}} /> : <CheckCircleFilled className='successIcon' />}
          </div>
        )
      },
    },
    // {
    //   title: "更新時間",
    //   dataIndex: "newUpdtT",
    //   key: "newUpdtT",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    // },
    {
      title: "更新時間",
      dataIndex: "updtT",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm")
    },
    {
      title: "更新人員",
      dataIndex: "updtID",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      align: "center",
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <PrinterOutlined style={{fontSize: '24px', marginRight: '5px'}} onClick={() => print(rowData)} />
          <OperateArea 
            onEditClick={() => editSOPHd(rowData)}
            onDeleteClick={() => removeSophd(rowData)}
          />
        </div>
      )
    }
  ]

  // 取得表頭資料
  useEffect(() => {

    if(s_showModal === false) call_getAllSophd();

  }, [s_showModal]);

  // 表身有資料時計算高度
  useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if(innerWidth >= 1440) {
      // alert(innerWidth)
      // 沒有表身資料
      if(Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } 
      else {
        setScrollY(ROWHEIGHT * 6);
      }
    }
    
    // 一般螢幕尺寸
    if(innerWidth >= 1920) {
      // 沒有表身資料
      if(Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } 
      else {
        setScrollY(ROWHEIGHT * 6);
      }
    }
  }, [c_lightData]);

  return (
    <>
      <Title 
        title='工位標準管理'
        placeholder='料號查詢'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStandard}
      />

      <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        rowClassName={(record, index) => {
          // const rowKey = record.PN + record.wpcset;
          // const dataKey = c_lightData.PN + c_lightData.wpcset;
          const rowKey = record.sopID;
          const dataKey = c_lightData.sopID;
          const clickRowStyle = rowKey === dataKey ? "clickRowStyle" : ""
          return index % 2 === 1 ? `light ${clickRowStyle}` : `dark ${clickRowStyle}`
          // return rowKey === dataKey ? "clickRowStyle" : "";
        }}
        
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.sopID;
              const dataKey = c_lightData.sopID;
              if (rowKey === dataKey) {
                set_c_lightData({})
              } else {
                set_c_lightData(record)
              }
            }
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_printModal ? (
        <CustomModal 
          width='60%' 
          title="列印"
          visible={s_printModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_printModal(false)}
        >
          <PrintModalContainer s_printData={s_printData} set_s_printData={set_s_printData} set_s_printModal={set_s_printModal} />
        </CustomModal>
      ) : null}

      {s_showModal ? (
        <CustomModal 
          width='60%' 
          title={ Object.keys(s_editData).length === 0 ? "新增工位明細" : "編輯工位明細" } 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_editData={s_editData} set_s_editData={set_s_editData} />
        </CustomModal>
      ) : null}

    </>
  )
};

export default HD;