import React, { useState, useEffect, useRef, useContext } from "react";
import { Input, message, Spin } from "antd";
import Workbook from "react-excel-workbook";

// import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from "../../../styled/publicTag.js";

import { DataContext } from "../index";

import { pcidPpidSearch } from "../../../services/api";

import { IconExcel } from "../../../components/Icon/index";

import Title from "../../../components/Custom/Title";
import CustomTable from "../../../components/antdCustom/CustomTable";
import CustomModal from "../../../components/antdCustom/CustomModal";

import ModalContainer from "./ModalContainer";

const innerWidth = window.innerWidth;

const HD = () => {
  let countRef = useRef(null);

  const [scrollY, setScrollY] = useState("");

  const [s_isLoading, set_s_isLoading] = useState(false);

  const [s_Excel, set_s_Excel] = useState([]);

  const [ExcelColumns, set_ExcelColumns] = useState([]);

  const [s_tableData, set_s_tableData] = useState([]);

  const [is_showModal, set_is_showModal] = useState(false);

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  // 後端回來的資料
  const [s_pageInfo, set_s_PageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // 送給後端的資料
  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 10,
    queryString: "",
  });

  const call_getBarcode = async () => {
    set_s_isLoading(true);
    const res = await pcidPpidSearch(s_searchValue);

    if (res.status) {
      set_s_tableData(res.data.tableData);
      set_s_PageInfo(res.data.pageInfo)
      message.success(res.msg)
    } else {
      set_s_tableData([]);
      message.error(res.msg)
    }

    set_s_isLoading(false);
  };

  // 取得全部表頭資料
  useEffect(() => {
    call_getBarcode()
  }, [s_searchValue]);

  const tableColumns = [
    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
    },
    {
      title: "生產序號",
      dataIndex: "pcid",
      align: "center",
    },
    {
      title: "掃入條碼",
      dataIndex: "itemcode",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
    },
    {
      title: "項目名稱",
      dataIndex: "itemNM",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: "掃碼項目",
      dataIndex: "itemanchor",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
  ];

  const handleClick = () => {
    set_is_showModal(true);
  };

  // 取得 Excel 表頭
  // useEffect(() => {
  //   const HDArray = [
  //     {
  //       title: "Model",
  //       key: "Model",
  //     },
  //     {
  //       title: "SSN",
  //       key: "SSN",
  //     },
  //     {
  //       title: "MB_Model",
  //       key: "MB_Model",
  //     },
  //     {
  //       title: "MB_SN",
  //       key: "MB_SN",
  //     },
  //     {
  //       title: "MB_PN",
  //       key: "MB_PN",
  //     },
  //   ];

  //   const dataArray = [...Array(30)].map((item, index) => {
  //     return [
  //       {
  //         title: `KP_${index + 1}_SN`,
  //         key: `KP_${index + 1}_SN`,
  //       },
  //       {
  //         title: `KP_${index + 1}_TYPE`,
  //         key: `KP_${index + 1}_TYPE`,
  //       },
  //       {
  //         title: `KP_${index + 1}_PN`,
  //         key: `KP_${index + 1}_PN`,
  //       },
  //     ];
  //   });

  //   const tmpArray = [].concat.apply([], HDArray.concat(dataArray));

  //   set_ExcelColumns(tmpArray);
  // }, []);

  // 取得 Excel 資料
  // useEffect(() => {
  //   // if(Object.keys(c_lightData).length === 0) return;

  //   const tmpArray = s_tableData.map((item, index) => {
  //     const dataArray = [...Array(30)].map((item1, index1) => {
  //       return {
  //         [`KP_${index1 + 1}_SN`]: item.KP[index1] ? item.KP[index1].SN : "",
  //         [`KP_${index1 + 1}_TYPE`]: item.KP[index1]
  //           ? item.KP[index1].TYPE
  //           : "",
  //         [`KP_${index1 + 1}_PN`]: item.KP[index1] ? item.KP[index1].PN : "",
  //       };
  //     });

  //     const bbb = {};

  //     dataArray.forEach((element) => {
  //       Object.keys(element).forEach((qqq) => {
  //         // console.log('qqq = ',qqq)
  //         bbb[qqq] = element[qqq];
  //       });
  //     });

  //     return {
  //       Model: item.Model,
  //       SSN: item.SSN,
  //       MB_Model: item.MB_Model,
  //       MB_SN: item.MB_SN,
  //       MB_PN: item.MB_PN,
  //       ...bbb,
  //     };
  //   });

  //   console.log("tmpArray = ", tmpArray);

  //   set_s_Excel(tmpArray);
  // }, [s_tableData]);

  const onSearch = (e) => {
    set_s_searchValue(prev=>{
      return {...prev, page:1, queryString:e}
    })
    set_c_lightData({});
  };


  return (
    <Spin size="large" spinning={s_isLoading}>
      <Title title="出貨條碼查詢">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Input.Search
            style={{width:"350px"}}
            className="Input"
            size="large"
            placeholder="請輸入工單號,生產序號,掃入條碼"
            onSearch={onSearch}
            enterButton
          />
          {/* <span style={{ height: "38px", cursor: "pointer" }}>

            <IconExcel
              style={{
                width: "30px",
                height: "38px",
                marginLeft: "10px",
                marginRight: "5px",
              }}
              onClick={handleClick}
            />
          </span> */}
        </div>
      </Title>

      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        columns={tableColumns.map(item => {
          item.width = `${parseInt(100 / (tableColumns.length) - 1)}%`
          return item
        })} dataSource={s_tableData.map((item, index) => {
          return { ...item, key: item.pcid + item.itemcode + index };
        })}
        rowClassName={(record) => {
          return (c_lightData.pcid + c_lightData.itemcode) === (record.pcid + record.itemcode) ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              set_c_lightData(record)
            },
          };
        }}
        pagination={{
          current: s_pageInfo.currentPage,
          total: s_pageInfo.totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            set_s_searchValue((prev) => {
              return { ...prev, page: current, pageSize: size };
            });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {is_showModal ? (
        <CustomModal
          width="60%"
          title="生產條碼查詢列印"
          visible={is_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
          afterClose={() => {
            // set_s_PSData({});
            // call_getAllPS();
          }}
        >
          <ModalContainer
            s_tableData={s_tableData}
            set_is_showModal={set_is_showModal}
          />
        </CustomModal>
      ) : null}
    </Spin>
  );
};

export default HD;
