import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';

import { addPost, modifyPost } from '../../../services/api';

message.config({
  duration: 1.5
})

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_JobTitleData}) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, s_JobTitleData]);

  const onSubmit = async values => {

    const type = Object.keys(s_JobTitleData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增職稱資料
      const res = await addPost(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };

    if(type === 'modify') {
      // 修改職稱資料
      const res = await modifyPost(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
    
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          postID: Object.keys(s_JobTitleData).length > 0 ? s_JobTitleData.postID : '',
          postNM: Object.keys(s_JobTitleData).length > 0 ? s_JobTitleData.postNM : '', 
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>職稱ID</label>}
              name="postID"
              rules={Object.keys(s_JobTitleData).length === 0 ? [{ required: true, message: '請填寫職稱ID!' }] : null}
            >
                <Input disabled={Object.keys(s_JobTitleData).length === 0 ? false : true} name='postID' placeholder="請填寫職稱ID" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>職稱名稱</label>}
              name="postNM"
              rules={[{ required: true, message: '請填寫職稱名稱!' }]}
            >
              <Input name='postNM' placeholder="請填寫職稱名稱" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer