import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import './ModalContainer.css'
import moment from 'moment'

import { getFunctions, getPushlvl, postUser, modifyUser } from '../../../services/api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { Form, Button, Input, Row, Col, Select, DatePicker, Radio, message, Divider } from 'antd';

const { Option } = Select;
const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_staffData, s_isLock}) => {
  
  const [s_Functions, set_s_Functions] = useState([]); // 組織功能 options
  const [s_Pushlvl, set_s_Pushlvl] = useState([]);     // 推播層級 options
  const [s_isUnSite, set_s_isUnSite] = useState('N')  // 是否離職的開關
  const [s_initialValues, set_s_initialValues] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('被修改的員工 = ', s_staffData)
    //console.log(s_staffData.function && s_staffData.function.length)
  }, [s_staffData])

  // call下拉選單api and 如果是編輯塞initialValues
  useEffect(() => {
    const call_getData = async () => {
      try {
        //取得組織功能的 Options
        const Functions_res = await getFunctions();
        //取得推播層級的 Options
        const Pushlvl_res = await getPushlvl();
        //console.log(Pushlvl_res.data)
        if(Functions_res.status === true) {
          set_s_Functions(Functions_res.data);
        } else {
          set_s_Functions([]);
        }
        if(Pushlvl_res.status === true) {
          set_s_Pushlvl(Pushlvl_res.data);
        } else {
          set_s_Functions([]);
        }
        
      } catch (e) {
        console.log('下拉選單api - 資料獲取失敗')
      }
      
    }
    call_getData();  //------------------ API 先關閉 ------------------
    
    //call_fake_getData()
  
  }, [])

  // 用來判斷 submit 時是新增還是修改 and 是否要開放編輯離職時間
  useEffect(() => {
    set_s_isUnSite(s_staffData && s_staffData.quitT ? 'Y' : 'N');
    set_s_initialValues(s_staffData);
  } ,[s_staffData]);

  //當 s_staffData 變化時重新建立form
  useEffect(() => {
    form.resetFields()
  }, [form, s_staffData]);

  const handleCancel = () => {
    set_s_showModal(false)
  }
  
  //送出時執行
  const onSubmit = async values => {

    const type = Object.keys(s_initialValues).length === 0 ? 'create' : 'edit';

    console.log(values)

    
    // 如果沒有離職，quitT 要傳 null 給後端
    if(s_isUnSite === 'N') {
      values.quitT = null
    }

    // 建立員工基本資料
    if(type === 'create') {
      const res = await postUser(values);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg)
      }
      set_s_showModal(false)
    }

    // 修改員工基本資料
    if(type === 'edit') {
      console.log("values = ", values)
      const res = await modifyUser(values.staffID, values);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg)
      }
      set_s_showModal(false)
    }    
    
  }
  
  const onFinishFailed = () => {
    console.log('onFinishFailed')
    //set_s_showModal(false)
  }

  return(
    <>
      <Form.Provider
        // 如果是否離職選擇 '否' 要清除離職時間
        onFormChange={(formName, info) => {
          const { changedFields } = info;
          if(changedFields[0].name[0] === 'isUnSite') {
            if(changedFields[0].value === 'N') {
              form.setFieldsValue({quitT: null})
            }
            set_s_isUnSite(changedFields[0].value)
          }
        }}
      >
      <StyledForm
        name="basic"
        form={form}
        initialValues={{
          staffID: s_staffData.staffID || '',
          peopleNM: s_staffData.peopleNM || '',
          cardID: s_staffData.cardID || '',
          mailaddr: s_staffData.mailaddr || '',
          tel: s_staffData.tel || '',
          personalID: s_staffData.personalID || '',
          // function: () => {
          //   if(s_staffData.function) {
          //     console.log(s_staffData.function.length)
          //   }
          //   return s_staffData.function && s_staffData.function.length > 0 ? s_staffData.function : null
          // },
          function: s_staffData.function  ? s_staffData.function : [],
          level: s_staffData.level ? s_staffData.level : undefined,
          proxyNM: s_staffData.proxyNM || '',
          proxytel: s_staffData.proxytel || '',
          enterT: s_staffData.enterT ? moment(s_staffData.enterT) : null,
          isUnSite: s_staffData.quitT ? 'Y' : 'N',
          quitT: s_staffData.quitT ? moment(s_staffData.quitT) : null,
        }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical" // horizontal
      >
        {Object.keys(s_initialValues).length > 0 ? (
          <Row style={{marginBottom: '12px', display: 'none'}}>
            <Col span={8}>
              <Row>
                <Col offset={2}>
                  <Button size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    <LeftOutlined />
                    上一筆
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                  下一筆
                  <RightOutlined />
                </Button>
              </div>

            </Col>
          </Row>
        ) : null}
        
        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{color: '#6C6C6C'}}>姓名</label>} 
              name="peopleNM" 
              rules={[{ required: true, message: '請輸入姓名!' }]}
            >
              <Input name='peopleNM' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>電話</label>} 
              name="tel" 
              rules={[{ required: true, message: '請輸入電話!' }]}
            >
              <Input name='tel' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>郵件地址</label>} 
              name="mailaddr" 
              rules={[{ required: true, message: '請輸入郵件地址!' }]}
            >
              <Input name='mailaddr' />
            </Form.Item>
          </Col>
        </Row>
          
        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>身份證號</label>} 
              name="personalID" 
              rules={[{ required: true, message: '請輸入身份證號!' }]}
            >
              <Input name='personalID' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>聯絡人姓名</label>} 
              name="proxyNM"
            >
              <Input name='proxyNM' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>聯絡人電話</label>} 
              name="proxytel"
            >
              <Input name='proxytel' />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{color: '#6C6C6C'}}>工號</label>} 
              name="staffID" 
              rules={[{ required: true, message: '請輸入工號!' }]}
            >
              <Input disabled={Object.keys(s_initialValues).length > 0 ? true : false} name='staffID' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>卡勤識別碼</label>} 
              name="cardID" 
              rules={[{ required: true, message: '請輸入卡勤識別碼!' }]}
            >
              <Input name='cardID' disabled={s_isLock && s_isLock === true ? true : false} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>入職日期</label>} 
              name="enterT" 
              rules={[{ required: true, message: '請選擇入職日期!' }]}
            >
              <DatePicker placeholder='請選擇入職日期' style={{width: '100%'}} disabled={s_isLock && s_isLock === true ? true : false} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>推播層級</label>} 
              name="level" 
              rules={[{ required: true, message: '請選擇推播層級!' }]}
            >
              <Select placeholder="選擇推播層級" allowClear disabled={s_isLock && s_isLock === true ? true : false} >
                {s_Pushlvl.length > 0 && s_Pushlvl.map(item => {
                  return <Option key={item.lvlcode} value={item.lvlcode}>{item.lvlNM}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>組織功能</label>} 
              name="function" 
              rules={[{ required: true, message: '請選擇組織功能!' }]}
            >
              <Select mode="multiple" showArrow placeholder="選擇組織功能" allowClear disabled={s_isLock && s_isLock === true ? true : false} >
                {s_Functions.length > 0 && s_Functions.map(item => {
                  return <Option key={item.funcode} value={item.funcode}>{item.funNM}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{marginBottom: '20px', borderTop: '1.5px solid rgba(0,0,0,0.06)'}} />

        <Row>
          <Col span={8}>
            <Row>
              {Object.keys(s_initialValues).length > 0 ? (
                <Col offset={2} style={{display: 'none'}}>
                  <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    據此創建新條目
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col offset={8} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
        
      </StyledForm>
      </Form.Provider>
    </>
  )
};

export default ModalContainer;

/*
<StyledForm
        name="basic"
        form={form}
        initialValues={{
          staffID: s_staffData.staffID || '',
          peopleNM: s_staffData.peopleNM || '',
          cardID: s_staffData.cardID || '',
          mailaddr: s_staffData.mailaddr || '',
          tel: s_staffData.tel || '',
          personalID: s_staffData.personalID || '',
          function: s_staffData.function ? s_staffData.function : undefined,
          level: s_staffData.level ? s_staffData.level : undefined,
          proxyNM: s_staffData.proxyNM || '',
          proxytel: s_staffData.proxytel || '',
          enterT: s_staffData.enterT ? moment(s_staffData.enterT) : null,
          isUnSite: s_staffData.quitT ? 'Y' : 'N',
          quitT: s_staffData.quitT ? moment(s_staffData.quitT) : null,
        }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical" // horizontal
      >
        <Row>
          <Col span={8} style={{backgroundColor: 'red'}}>
            <Form.Item 
              labelCol={{span:4, offset:3}} 
              wrapperCol={{span:16, offset:3}} 
              label={<label style={{color: '#6C6C6C'}}>工號</label>} 
              name="staffID" 
              rules={[{ required: true, message: '請輸入工號!' }]}
            >
              <Input disabled={Object.keys(s_initialValues).length > 0 ? true : false} name='staffID' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{span:4}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>姓名</label>} 
              name="peopleNM" 
              rules={[{ required: true, message: '請輸入姓名!' }]}
            >
              <Input name='peopleNM' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{span:6}} 
              wrapperCol={{span:16}}
              label={<label style={{color: '#6C6C6C'}}>管制卡號</label>} 
              name="cardID" 
              rules={[{ required: true, message: '請輸入管制卡號!' }]}
            >
              <Input name='cardID' />
            </Form.Item>
          </Col>
        </Row>
          
        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{span:4}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>郵址</label>} 
              name="mailaddr" 
              rules={[{ required: true, message: '請輸入郵址!' }]}
            >
              <Input name='mailaddr' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{span:4}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>電話</label>} 
              name="tel" 
              rules={[{ required: true, message: '請輸入電話!' }]}
            >
              <Input name='tel' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{span:6}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>身份證號</label>} 
              name="personalID" 
              rules={[{ required: true, message: '請輸入身份證號!' }]}
            >
              <Input name='personalID' />
            </Form.Item>
          </Col>
        </Row>
          
        <Row>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:6}} 
              wrapperCol={{span:17}}  
              label={<label style={{color: '#6C6C6C'}}>組織功能</label>} 
              name="function" 
              rules={[{ required: true, message: '請選擇組織功能!' }]}
            >
              <Select placeholder="選擇組織功能" allowClear >
                {s_Functions.length > 0 && s_Functions.map(item => {
                  return <Option key={item.funcode} value={item.funcode}>{item.funNM}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:6}} 
              wrapperCol={{span:17}} 
              label={<label style={{color: '#6C6C6C'}}>推播層級</label>} 
              name="level" 
              rules={[{ required: true, message: '請選擇推播層級!' }]}
            >
              <Select placeholder="選擇推播層級" allowClear >
                {s_Pushlvl.length > 0 && s_Pushlvl.map(item => {
                  return <Option key={item.lvlcode} value={item.lvlcode}>{item.lvlNM}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:6}} 
              wrapperCol={{span:17}} 
              label={<label style={{color: '#6C6C6C'}}>聯絡人姓名</label>} 
              name="proxyNM"
            >
              <Input name='proxyNM' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:7}} 
              wrapperCol={{span:17}}  
              label={<label style={{color: '#6C6C6C'}}>聯絡人電話</label>} 
              name="proxytel"
            >
              <Input name='proxytel' />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:6}} 
              wrapperCol={{span:17}} 
              label={<label style={{color: '#6C6C6C'}}>入職時間</label>} 
              name="enterT" 
              rules={[{ required: true, message: '請選擇入職時間!' }]}
            >
              <DatePicker placeholder='請選擇入職時間' style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              className="customItem" 
              labelCol={{span:6}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>是否離職</label>} 
              name="isUnSite"
            >
              <Radio.Group 
                options={[
                  { label: '是', value: 'Y' },
                  { label: '否', value: 'N' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{span:6}} 
              wrapperCol={{span:16}} 
              label={<label style={{color: '#6C6C6C'}}>離職時間</label>} 
              name="quitT" 
              rules={s_isUnSite === 'Y' ? [{ required: true, message: '請選擇離職時間!' }] : null}
            >
              <DatePicker 
                disabled={s_isUnSite === 'N' ? true : false} 
                placeholder='請選擇離職時間' 
                style={{width: '100%'}} 
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col offset={16} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px'}}>
                  保存
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
        
      </StyledForm>
*/