import React, { useEffect,useState } from 'react';
import styled from 'styled-components';
import { getAllProduct } from '../../../services/api';
import { modifySoprec } from '../../../services/apis/sopAPI';
import { Form, Button, Row, Col, Input, Select, message } from 'antd';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const RecModalContainer = ({s_editData, set_is_showModal, set_s_editData}) => {
  
  const [form] = Form.useForm();

  const [s_itemanchor, set_s_itemanchor] = useState([]);

  // 第一次渲染取得料號、ws、wl 下拉選單
  useEffect(() => {
    const call_getAllProduct = async () => {
      try {
        const res = await getAllProduct();
        console.log('全部料號資料 = ', res)
        if(res.status === true) {
          set_s_itemanchor(res.data)
        } else {
          set_s_itemanchor([])
        }
      } catch (e) {
        set_s_itemanchor([]);
        console.log('-------------------- call_getAllProduct error --------------------')
      }
    };
    call_getAllProduct();
  }, []);

  const handleCancel = () => {
    set_is_showModal(false)
    set_s_editData({})
  }

  const onSubmit = values => {
    const call_modifySoprec = async () => {
      console.log('要傳給後端的資料 = ', {
        ...s_editData,
        ...values
      })
      // return
      const res = await modifySoprec({
        ...s_editData,
        ...values
      });
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false)
      set_s_editData({})
    }
    call_modifySoprec()
  }

  const checkitem_map = {
    'labor': '人員掃碼',
    'equip': '設備掃碼',
    'feed': '部品掃碼'
  }

  return (
    <Form.Provider

        onFormChange={(formName, info) => {
          const { changedFields } = info;
          console.log(changedFields)
          // 改變料號要自動帶入品名
          if(changedFields[0].name[0] === 'itemanchor') {
            if(changedFields[0].value === '' || changedFields[0].value === undefined) {
              form.setFieldsValue({
                'pdtNM': ''
              });
            } else {
              const choosePN = s_itemanchor.find(item => item.PN === changedFields[0].value)
              form.setFieldsValue({
                'pdtNM': choosePN ? choosePN.pdtNM : '',
              });
            }
          }
          
        }}
        
      >
      <CustomForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          'recno': Object.keys(s_editData).length > 0 ? s_editData.recno : null,
          // 'checkitem': Object.keys(s_editData).length > 0 ? checkitem_map[s_editData.checkitem] : null, // 比對項目
          'checkitem_str': Object.keys(s_editData).length > 0 ? checkitem_map[s_editData.checkitem] : null, // 比對項目
          'checkitem': Object.keys(s_editData).length > 0 ? s_editData.checkitem : null, // 比對項目
          'itemNM': Object.keys(s_editData).length > 0 ? s_editData.itemNM : null, // 項目名稱
          'itemanchor': Object.keys(s_editData).length > 0 ? s_editData.itemanchor : '', // 部品料號
          'itemkw': Object.keys(s_editData).length > 0 ? s_editData.itemkw : null, // 防呆字串
          'pdtNM': Object.keys(s_editData).length > 0 ? s_editData.pdtNM : null, // 部品品名
          'note': Object.keys(s_editData).length > 0 ? s_editData.note : null, // 備註
          'updtT': Object.keys(s_editData).length > 0 ? s_editData.updtT : null, // 備註
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>掃碼類別</label>}
              name="checkitem_str"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>項序</label>}
              name="recno"
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>項目名稱</label>}
              name="itemNM"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>部品品名</label>}
              name="pdtNM"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>識別字串(請勿輸入中文)</label>}
              name="itemanchor"
              rules={[{ required: true, message: '請輸入部品料號!' }]}
            >
              <Select 
                placeholder="選擇部品料號"
                allowClear
                showSearch
                // disabled={Object.keys(s_editData).length > 0 ? true : false}
              >
                {s_itemanchor.length > 0 && s_itemanchor.map((item, index) => {
                  return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                })}
              </Select>
              {/* <Input /> */}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label>防呆字串</label>}
              name="itemkw"
            >
              <Input />
            </Form.Item>
          </Col>
            
          <Col span={24}>
            <Form.Item
              label={<label style={{color: '#6C6C6C'}}>備註</label>}
              name="note"
            >
              <Input.TextArea name='note' rows={3} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={18}>
            {Object.keys(s_editData).length > 0 ? (
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px', display: 'none'}}>
                據此條目創建
              </Button>
            ) : null}
          </Col>
          <Col span={6}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>

      </CustomForm>
    </Form.Provider>
  )
};

export default RecModalContainer;