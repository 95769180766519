import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, message, Divider, Radio } from 'antd';
import { addStation, modifyStation } from '../../../services/api';

message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_Stationata}) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    console.log('要被編輯的工站資料 = ', s_Stationata)
  }, [form, s_Stationata]);

  const onSubmit = async values => {

    const type = Object.keys(s_Stationata).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增工站資料
      // const call_addStation = async () => {
      //   console.log('要新增的工站資料 = ', values)
      //   const res = await addStation(values);
      //   res.status ? message.success(res.msg) : message.error(res.msg);
      // };
      // call_addStation();
      const res = await addStation(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };

    if(type === 'modify') {
      // 修改工站資料
      // const call_modifyStation = async () => {
      //   console.log('values.wsID = ', values.wsID)
      //   console.log('傳送給後端的資料 = ', values)
      //   const res = await modifyStation(values.wsID, values);
      //   res.status ? message.success(res.msg) : message.error(res.msg);
      // };
      // call_modifyStation();
      console.log('要傳給後端的資料 = ', values)
      const res = await modifyStation(values.wsID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
    
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          wsID: Object.keys(s_Stationata).length > 0 ? s_Stationata.wsID : '',
          wsNM: Object.keys(s_Stationata).length > 0 ? s_Stationata.wsNM : '', 
          set0wpc: Object.keys(s_Stationata).length > 0 ? s_Stationata.set0wpc : 'N',          // 設定短單
          set1gpc: Object.keys(s_Stationata).length > 0 ? s_Stationata.set1gpc : 'N',          // 設定工段
          set2mold: Object.keys(s_Stationata).length > 0 ? s_Stationata.set2mold : 'N',        //設定模具同步
          set3whm: Object.keys(s_Stationata).length > 0 ? s_Stationata.set3whm : 'N',          //設定工時
          set4feedlot: Object.keys(s_Stationata).length > 0 ? s_Stationata.set4feedlot : 'N',  // 設定原料投料
          set5pid: Object.keys(s_Stationata).length > 0 ? s_Stationata.set5pid : 'N',        // 設定條碼管理
          set6alm: Object.keys(s_Stationata).length > 0 ? s_Stationata.set6alm : 'N',          // 設定輔線
          set7netwt: Object.keys(s_Stationata).length > 0 ? s_Stationata.set7netwt : 'N',      //設定淨重劑量
          set8sop: Object.keys(s_Stationata).length > 0 ? s_Stationata.set8sop : 'N',    // 設定SOP監控
          set9sip: Object.keys(s_Stationata).length > 0 ? s_Stationata.set9sip : 'N',          // 設定SIP監控
          setarpm: Object.keys(s_Stationata).length > 0 ? s_Stationata.setarpm : 'N',          // 設定配方

          MC: Object.keys(s_Stationata).length > 0 ? s_Stationata.MC : 'N',          // 設定物控作業
          QC: Object.keys(s_Stationata).length > 0 ? s_Stationata.QC : 'N',          // 設定質控作業
          WOC: Object.keys(s_Stationata).length > 0 ? s_Stationata.WOC : 'N',        // 設定生產控制
          SFC: Object.keys(s_Stationata).length > 0 ? s_Stationata.SFC : 'N',        // 設定工站報工
          OUT: Object.keys(s_Stationata).length > 0 ? s_Stationata.OUT : 'N',        // 設定委外報工
          PDT: Object.keys(s_Stationata).length > 0 ? s_Stationata.PDT : 'N',        // 設定生產查詢
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              name="wsID"
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>工站ID</label>}
              rules={Object.keys(s_Stationata).length === 0 ? [{ required: true, message: '請填寫工站ID!' }] : null}
            >
                <Input disabled={Object.keys(s_Stationata).length === 0 ? false : true} name='wsID' placeholder="請填寫工站ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="wsNM"
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>工站名稱</label>}
              rules={[{ required: true, message: '請填寫工站名稱!' }]}
            >
              <Input name='wsNM' placeholder="請填寫工站名稱" />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px 6px 24px 6px'}} />

        <Row>
          <Col span={6}>
            <Form.Item 
              name='set0wpc'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定短單:</label>} 
            >
              <Radio.Group className="center" name="set0wpc">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set1gpc'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工段:</label>} 
            >
              <Radio.Group className="center" name="set1gpc">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set2mold'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定模具同步管理:</label>} 
            >
              <Radio.Group className="center" name="set2mold">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set3whm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工時管理:</label>} 
            >
              <Radio.Group className="center" name="set3whm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

        </Row>

        <Row>
          
          <Col span={6}>
            <Form.Item 
              name='set4feedlot'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定原料投料:</label>} 
            >
              <Radio.Group className="center" name="set4feedlot">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set5pid'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定條碼管理:</label>} 
            >
              <Radio.Group className="center" name="set5pid">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set6alm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定輔線:</label>} 
            >
              <Radio.Group className="center" name="set6alm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set7netwt'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定淨重劑量:</label>} 
            >
              <Radio.Group className="center" name="set7netwt">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          
          <Col span={6}>
            <Form.Item 
              name='setarpm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定淨配方管理:</label>} 
            >
              <Radio.Group className="center" name="setarpm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set8sop'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定SOP監控管理:</label>} 
            >
              <Radio.Group className="center" name="set8sop">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='set9sip'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定SIP監控管理:</label>} 
            >
              <Radio.Group className="center" name="set9sip">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          
          <Col span={6}>
            <Form.Item 
              name='MC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定物控作業:</label>} 
            >
              <Radio.Group className="center" name="MC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item 
              name='QC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定質控作業:</label>} 
            >
              <Radio.Group className="center" name="QC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='WOC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定生產控制:</label>} 
            >
              <Radio.Group className="center" name="WOC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='SFC'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工站報工:</label>} 
            >
              <Radio.Group className="center" name="WOC">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name='OUT'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定委外報工:</label>} 
            >
              <Radio.Group className="center" name="OUT">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item 
              name='PDT'
              labelCol={{offset:2}}
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定生產查詢:</label>} 
            >
              <Radio.Group className="center" name="PDT">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px 6px 24px 6px'}} />

        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer