import React, { useState, useEffect } from 'react';

import { MySubtitleH4 } from '../../../styled/publicTag.js';

import {getAllSoprec} from '../../../services/apis/sopAPI';

import CustomModal from '../../../components/antdCustom/CustomModal';
import CustomTable from '../../../components/antdCustom/CustomTable';

import { IconPen } from '../../../components/Icon/newicon';

import RecModalContainer from './RecModalContainer';
import { message } from 'antd';

const innerWidth = window.innerWidth;

const RecModal = ({s_rec, set_s_showRecModal}) => {

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_editData, set_s_editData] = useState({});

  const [is_showModal, set_is_showModal] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  useEffect(() => {
    const call_getAllSoprec = async () => {
      const res = await getAllSoprec({
        sopID: s_rec.sopID,
        version: s_rec.version,
        sopno: s_rec.sopno
      });

      console.log('getAllSoprec = ', res)

      if(res.status === true) {
        message.success(res.msg);
        set_s_tableData(res.data);
      } else {
        message.error(res.msg);
        set_s_tableData([]);
      }
    }
    call_getAllSoprec()
  }, [is_showModal])

  const edit = rowData => {
    set_s_editData(rowData);
    set_is_showModal(true);
  }

  const tableColumns = [
    {
      title: "類序",
      dataIndex: "recno",
      key: "recno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text, rowData, index) => {
        return index+1
      }
    },
    {
      title: "掃碼類別",
      dataIndex: "checkitem_str",
      key: "checkitem_str",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text, rowData, index) => {
        const map = {
          'labor': '人員掃碼',
          'equip': '設備掃碼',
          'feed': '部品掃碼',
        }

        return map[rowData.checkitem]
      }
    },
    {
      title: "項序",
      dataIndex: "recno",
      key: "recno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "項目名稱",
      dataIndex: "itemNM",
      key: "itemNM",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "料件品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "識別字串",
      dataIndex: "itemanchor",
      key: "itemanchor",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "防呆字串",
      dataIndex: "itemkw",
      key: "itemkw",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{fontSize: '26px', cursor: 'pointer'}}>
          <IconPen 
            onClick={event => {
              event.stopPropagation();
              edit(rowData)
            }}
          />
        </div>
      )
    }
  ];

  return (
    <>
    <CustomTable
      size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
      scroll={{ x: 'max-content'}}
      columns={tableColumns}
      dataSource={s_tableData.map((item, index) => {
        return {
          key: index,
          ...item
        }
      })}
      pagination={{
        defaultCurrent: 1,
        total: pageInfo.totalItems,
        // current: abcBoardConfig.page,
        showSizeChanger: true,
        pageSize: pageInfo.pageSize,
        pageSizeOptions: ["10", "20", "30"],
        onChange: (current, size) => {
          setPageInfo({
            ...pageInfo,
            pageSize: size
          })
        },
        showTotal: (total, range) => (
          <MySubtitleH4>
            共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
          </MySubtitleH4>
        ),
      }}
    />
      {is_showModal ? (
        <CustomModal
          width='60%'
          // title='SOP投料全局項目編輯'
          title="掃碼設定"
          visible={is_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
        >
          <RecModalContainer
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            set_is_showModal={set_is_showModal}
          />
        </CustomModal>
      ) : null}
    </>
  )
};

export default RecModal;