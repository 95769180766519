import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col, Table, message, Select, Empty, Progress } from "antd";

import { MyMainColorH1 } from "../../styled/publicTag";
import { getDashBoard } from "../../services/api";
import moment from "moment";
import { numberWithCommas } from "../../utility/numberWithCommas";
import Title from "../../components/Custom/Title";
import CustomTable from "../../components/antdCustom/CustomTable";
import Light from "./Light";

const Header = styled.div`
  display: flex;
  align-items: center;

  // ipad mini
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const PosProgress = ({ qty = -1, rate = 0 }) => {
  return qty === -1 ? (
    <div className="PosProgress">
      <div style={{ borderBottom: "1px solid #f0f0f0" }}>NA</div>
      <div>-</div>
    </div>
  ) : (
    <div className="PosProgress">
      <div style={{ borderBottom: "1px solid #f0f0f0", fontSize: "25px" }}>
        {qty}
      </div>
      <Progress
        percent={rate}
        size="large"
        strokeColor={rate === 100 ? "#52c41a" : "#FFBB00"}
      />
    </div>
  );
};

const DashBoardCard = () => {
  const screens = Grid.useBreakpoint();
  const DATEFORMAT = "YYYY-MM-DD HH:mm:ss";

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [s_hight, set_s_hight] = useState(window.innerHeight);

  const tableColumns = [
    {
      title: "燈號",
      dataIndex: "lifeF",
      align: "center",
      width: "70px",
      render: (text) => <Light size={40} color={text} />,
      sorter: (a, b) => a.lifeF.localeCompare(b.lifeF),
    },
    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
      className: "dashboard-font-size",
      width: "230px",
      sorter: (a, b) => a.woN.localeCompare(b.woN),
    },
    {
      title: "料號",
      dataIndex: "PN",
      width: "250px",
      className: "dashboard-font-size",
      align: "center",
      sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "計畫產量",
      dataIndex: "planqty",
      className: "dashboard-font-size",
      align: "center",
      width: "120px",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "工位-01",
      dataIndex: "pos-01",
      align: "center",
      onCell: (record) =>
        record.pos[0].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[0]} />,
    },
    {
      title: "工位-02",
      dataIndex: "pos-02",
      align: "center",
      onCell: (record) =>
        record.pos[1].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[1]} />,
    },
    {
      title: "工位-03",
      dataIndex: "pos-03",
      align: "center",
      onCell: (record) =>
        record.pos[2].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[2]} />,
    },
    {
      title: "工位-04",
      dataIndex: "pos-04",
      align: "center",
      onCell: (record) =>
        record.pos[3].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[3]} />,
    },
    {
      title: "工位-05",
      dataIndex: "pos-05",
      align: "center",
      onCell: (record) =>
        record.pos[4].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[4]} />,
    },
    {
      title: "工位-06",
      dataIndex: "pos-06",
      align: "center",
      onCell: (record) =>
        record.pos[5].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[5]} />,
    },
    {
      title: "工位-07",
      dataIndex: "pos-07",
      align: "center",
      onCell: (record) =>
        record.pos[6].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[6]} />,
    },
    {
      title: "工位-08",
      dataIndex: "pos-08",
      align: "center",
      onCell: (record) =>
        record.pos[7].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[7]} />,
    },
    {
      title: "工位-09",
      dataIndex: "pos-09",
      align: "center",
      onCell: (record) =>
        record.pos[8].qty === 0 && { style: { backgroundColor: "#FFD9D9" } },
      render: (_, rowData) => <PosProgress {...rowData.pos[8]} />,
    },
    {
      title: "完成數量",
      dataIndex: "finish",
      render: (text, rowData) => (
        <PosProgress qty={numberWithCommas(text)} rate={rowData.finishRate} />
      ),
      align: "center",
      className: "bg-f9e9d7ac",
    },
    // {
    //   title: "累積生產時間",
    //   dataIndex: "spendTime",
    //   className: "dashboard-font-size",
    //   align: "center",
    //   render: (arr) => (
    //     <span style={{ color: "blue" }}>{arr ? `${arr[0]}天` : ""}</span>
    //   ),
    // },
    {
      title: "歷程時間",
      dataIndex: "lifeFT",
      className: "dashboard-font-size",
      width: "200px",
      align: "center",
      render: (text) => (
        <>
          {moment(text).format("YYYY-MM-DD")}
          <br />
          {moment(text).format("HH:mm")}
        </>
      ),
      sorter: (a, b) => {
        const moment1 = moment(a.lifeFT);
        const moment2 = moment(b.lifeFT);
        if (moment1.isBefore(moment2)) {
          return -1;
        } else if (moment1.isAfter(moment2)) {
          return 1;
        } else {
          return 0;
        }
      },
    },
    {
      title: "完工期限",
      dataIndex: "dueday",
      className: "dashboard-font-size",
      width: "200px",
      align: "center",
      render: (text) => {
        const now = moment();
        const dueday = moment(text);
        if (moment(dueday).isAfter(now)) {
          //日期在今天之后
          return <span>{dueday.format("YYYY-MM-DD")}</span>;
        } else if (moment(dueday).isBefore(now)) {
          //日期在今天之前
          return (
            <span style={{ color: "red" }}>{dueday.format("YYYY-MM-DD")}</span>
          );
        } else {
          return (
            //日期是今天
            <span style={{ color: "red", fontWeight: 600 }}>
              {dueday.format("YYYY-MM-DD")}
            </span>
          );
        }
      },
      sorter: (a, b) => {
        const moment1 = moment(a.dueday);
        const moment2 = moment(b.dueday);
        if (moment1.isBefore(moment2)) {
          return -1;
        } else if (moment1.isAfter(moment2)) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  ];

  const call_getDashBoard = async () => {
    try {
      setLoading(true);
      const res = await getDashBoard();
      setTableData(res.data.map((x, i) => ({ ...x, key: i })));
      message.success("資料取得成功");
      setLoading(false);
    } catch (e) {
      console.log("資料獲取失敗", e);
    }
  };

  useEffect(() => {
    const rowHeight = 30 * 1.5 + 13;
    // const isTbMode = Object.keys(tbMode).length > 0;
    // 一般螢幕尺寸
    if (screens.xxl) {
      // 表身資料
      setScrollY(rowHeight * 12);
    } else if (screens.xl) {
      // macbook 尺寸
      setScrollY(rowHeight * 15);
    }
  }, [screens]);

  useEffect(() => {
    call_getDashBoard();
  }, []);

  useEffect(() => {
    const handleRWD = () => {
      set_s_hight(window.innerHeight);
    };
    window.addEventListener("resize", handleRWD);
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  console.log(s_hight);

  return (
    <div style={{ marginLeft: "15px", marginTop: "15px" }}>
      {/* <MyMainColorH1>數位生產圖框</MyMainColorH1> */}
      <Title title="數位生產看板">
        <Header></Header>
      </Title>

      <CustomTable
        dataSource={tableData}
        columns={tableColumns}
        loading={loading}
        // scroll={{ y: window.innerHeight - 230 }}
        scroll={{ y: s_hight - 230 }}
        bordered={true}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "bg-grey" : "bg-white"
        }
        // scroll={{ x: "max-content", y: scrollY }}
      />
    </div>
  );
};

export default DashBoardCard;
