/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Divider } from "antd";
import { useReactToPrint } from "react-to-print";

import { printPosition } from '../../../../services/apis/sopAPI';

import QRCode from "qrcode.react";
import JsBarcode from "jsbarcode";
import Barcode from "react-jsbarcode";

const Wrapper = styled.div`
  width: 794px;
  height: 100%;

  .w-150 {
    width: 150px;
  }

  .h-80px {
    height: 80px;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .header .header-title {
    font-size: 24px;
    font-weight: bold;
    background-color: yellow;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
  }

  .header .header-container {
    display: flex;
    flex-direction: row;
  }

  .header .header-container .header-titles {
    display: flex;
    flex-direction: column;
  }

  .flex-1 {
    flex:1
  }

  .header .header-container .header-titles .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    padding: 5px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .header .header-container .header-values {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .header .header-container .header-values .value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    padding: 5px;
    font-size: 17px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .header .header-container .header-barcode {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 150px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .header .header-container .header-barcode .barcode {
    width: 100px;
    height: 100px;
    background-color: #000;
  }

  .main {
    margin-top: 15px;
  }

  .main .main-title {
    font-size: 24px;
    font-weight: bold;
    background-color: yellow;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
  }

  .main .main-container {
    display: flex;
    flex-direction: row;
    border-right: 1px solid #000;
  }

  .main .main-container .main-titles {
    display: flex;
    flex-direction: column;
  }

  .main .main-container .main-titles .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    padding: 5px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    flex: 1;
  }

  .main .main-container .main-values {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .main .main-container .main-values .barcord-img {
    display: flex;
    justify-content: center;
    /* flex: 1; */
    border-bottom: 1px solid #000;
  }

  /* .main .main-container .main-values .value {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 17px;
    flex: 1;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000; */

  .footer {
    margin-top: 15px;
  }

  .footer .footer-title {
    font-size: 24px;
    font-weight: bold;
    background-color: yellow;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
  }

  .footer .footer-container {
    display: flex;
    flex-direction: row;
    border-right: 1px solid #000;
  }

  .footer .footer-container .footer-titles {
    display: flex;
    flex-direction: column;
  }

  .footer .footer-container .footer-titles .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    padding: 5px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    flex: 1;
  }

  .footer .footer-container .footer-values {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .footer .footer-container .footer-values .value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    padding: 5px;
    font-size: 17px;
    flex: 1;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .footer .footer-container .footer-barcodes {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }

  .footer .footer-container .footer-barcode {
    border-bottom: 1px solid #000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 50px; */
    /* flex: 1; */
    /* background-color: red; */
  }

  /* .footer .footer-container .footer-barcode .barcode {
    background-color: red;
  } */

  /* .footer .footer-container .footer-barcode .barcode {
    width: 250px;
    height: 25px;
    background-color: #000;
  } */

  .footer .Feeding-title {
    font-size: 17px;
    font-weight: bold;
    padding: 5px;
    /* background-color: yellow; */
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: flex-start;
  }

  .footer .Feeding-container {
    display: flex;
    flex-direction: column;
  }

  .footer .Feeding-container .Feeding-items {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #000;
    /* border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000; */
  }

  .footer .Feeding-container .Feeding-items .Feeding-itemx {
    display: flex; 
    justify-content: center;
    align-items: center;

    font-size: 17px;
    padding: 5px;
    flex: 1;
    /* border-left: 1px solid #000; */
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .footer .Feeding-container .Feeding-items .Feeding-item {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    height: 77px;
    
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    flex: 1;
    font-size: 17px;
    padding: 25px 5px;
    /* border-left: 1px solid #000; */
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .footer .Feeding-container .Feeding-items .Feeding-barcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* height: 100%; */
    font-size: 17px;
    padding: 20px 5px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .footer .Feeding-container .Feeding-items .Feeding-barcode .barcode {
    width: 200px;
    height: 27px;
    /* display: flex;
    flex: 1; */
    /* background-color: #000; */
  }
`

const FormTemplate = ({data}) => {

  console.log('data = ',data)

  const END_ref = useRef(""); //查核過站
  const SKIP_ref = useRef(""); // 強制加插
  const CLEAR_ref = useRef(""); // 重置序號
  const FORCEEND_ref = useRef(""); // 強制過站
  const RESET_ref = useRef(""); // 清除全部
  const REMOVE_ref = useRef(""); // 取消條碼
  const METHOD_ref = useRef("");
  const FEED_ref = useRef("");

  const labor_ref = useRef("");
  const equip_ref = useRef("");

  function convertCanvasToImage(canvas) {
    // console.log(canvas)
    var image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image;
  }

  // 查核過站
  useEffect(() => {
    let canvas = document.createElement("canvas")
    JsBarcode(canvas, "END", {
      displayValue: false,
      // textMargin: 0,
      // margin: 5,
      // marginBottom: 0,
      // fontSize:50,
      width:1,
      height:20,
    });
    END_ref.current.appendChild(convertCanvasToImage(canvas));
  });

  // 強制加插
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "SKIPKW", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   SKIP_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  // 重置序號
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "CLEAR", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   CLEAR_ref.current.appendChild(convertCanvasToImage(canvas));
  // });  

  // 強制過站
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "FORCEEND", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   FORCEEND_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  // 強制替換
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "END", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   CHANGE_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  // 強制過站
  useEffect(() => {
    let canvas = document.createElement("canvas")
    JsBarcode(canvas, "RESET", {
      displayValue: false,
      // textMargin: 0,
      // margin: 5,
      // marginBottom: 0,
      // fontSize:50,
      width:1,
      height:20,
    });
    RESET_ref.current.appendChild(convertCanvasToImage(canvas));
  });

  // 取消條碼
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "REMOVE", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   REMOVE_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  // 作業人員
  useEffect(() => {
    let canvas = document.createElement("canvas")
    JsBarcode(canvas, "labor", {
      displayValue: false,
      // textMargin: 0,
      // margin: 5,
      // marginBottom: 0,
      // fontSize:50,
      width:1,
      height:20,
    });
    labor_ref.current.appendChild(convertCanvasToImage(canvas));
  });

  // 機器設備
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "equip", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   equip_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  // 投料作業
  // useEffect(() => {
  //   let canvas = document.createElement("canvas")
  //   JsBarcode(canvas, "feed", {
  //     displayValue: false,
  //     // textMargin: 0,
  //     // margin: 5,
  //     // marginBottom: 0,
  //     // fontSize:50,
  //     width:1,
  //     height:20,
  //   });
  //   FEED_ref.current.appendChild(convertCanvasToImage(canvas));
  // });

  return (
    <Wrapper className="Wraper pointer-box pagebreak" key={Math.random() * 1000}>
      
      <div className="header">
        
        <div className="header-title">{data.title}</div>

        <div className="header-container">

          <div className="header-titles">
            <div className="title w-150">料號</div>
            <div className="title w-150 flex-1">品名</div>
            <div className="title w-150">SOP文編</div>
            <div className="title w-150">SOP版碼</div>
          </div>

          <div className="header-values">
            <div className="value">{data.PN}</div>
            <div className="value">{data.pdtNM}</div>
            <div className="value">{data.sopID}</div>
            <div className="value">{data.version}</div>
          </div>

          <div className="header-barcode">
            {/* <div className="barcode"></div> */}
            <QRCode
              id={123}
              value={data.QR} //value引數為生成二維碼的連結 我這裡是由後端返回
              size={100} //二維碼的寬高尺寸
              fgColor="#020202" //二維碼的顏色
            />
          </div>

        </div>

      </div>

      <div className="main">

        <div className="main-title">操作指令</div>

        <div className="main-container">

          <div className="main-titles">
            <div className="title w-150 h-80px">序號查核過站</div>
            {/*1 <div className="title w-150 h-80px">序號強制過站</div>
            <div className="title w-150 h-80px">取消識別字串</div> */}
          </div>

          <div className="main-values">
            {/* <div className="value">PN20220411</div>
            <div className="value">品名_2000_04_11</div>
            <div className="value">2022-04-11</div> */}
            <div className="flex-center h-80px barcord-img">
              <div ref={END_ref} ></div>
            </div>

            {/*1 <div className="flex-center h-80px barcord-img">
              <div ref={FORCEEND_ref} ></div>
            </div>

            <div className="flex-center h-80px barcord-img">
              <div ref={REMOVE_ref} ></div>
            </div> */}
            
          </div>

          <div className="main-titles">
            <div className="title w-150 h-80px">重新綁定工位</div>
            {/*1 <div className="title w-150 h-80px">跳過關鍵判斷</div>
            <div className="title w-150 h-80px">重置生產序號</div> */}
            {/* <div className="title w-150"></div> */}
          </div>

          <div className="main-values">
            <div className="flex-center h-80px barcord-img">
              <div ref={RESET_ref} ></div>
            </div>
            
            {/*1 <div className="flex-center h-80px barcord-img">
              <div ref={SKIP_ref} ></div>
            </div>

            <div className="flex-center h-80px barcord-img">
              <div ref={CLEAR_ref}></div>
            </div> */}

            {/* <div ref={CHANGE_ref} className="barcord-img"></div> */}
          </div>

        </div>

      </div>


      <div className="footer">

        <div className="footer-title">採集條碼</div>

        <div className="footer-container">

          <div className="footer-titles">
            <div className="title w-150">作業人員</div>
            {/* <div className="title w-150">機器設備</div> */}
            {/* <div className="title w-150">生產投料</div> */}
          </div>

          <div className="footer-values">
            <div className="value">labor</div>
            {/* <div className="value">equip</div> */}
            {/* <div className="value">feed</div> */}
          </div>

          <div className="footer-barcodes">

            <div className="footer-barcode">
              {/* <div className="barcode"></div> */}
              <div className="barcode">
                <div ref={labor_ref} className="barcord-img"></div>
              </div>
            </div>
            
            {/* <div className="footer-barcode">
              <div className="barcode">
                <div ref={equip_ref} className="barcord-img"></div>
              </div>
            </div> */}

            {/* <div className="footer-barcode">
              <div className="barcode">
                <div ref={METHOD_ref} className="barcord-img"></div>
              </div>
            </div>

            <div className="footer-barcode">
              <div className="barcode">
                <div ref={FEED_ref} className="barcord-img"></div>
              </div>
            </div> */}

          </div>

        </div>

        <div className="Feeding-title">投料條碼</div>

        <div className="Feeding-container">
          
          <div className="Feeding-items">
            <div className="Feeding-itemx">項目名稱</div>
            <div className="Feeding-itemx">識別字串</div>
            <div className="Feeding-itemx">項目條碼</div>
          </div>

          {data.rec.map((item, index) => {
            console.log(item);
            return (
            <div key={index} className="Feeding-items">
              <div className="Feeding-item">{item.itemNM}</div>
              <div className="Feeding-item">{item.itemanchor}</div>
              <div className="Feeding-barcode">
                {/* <div className="barcode"> */}
                {item.itemanchor !== '' ? (
                  <Barcode
                    value={item.itemanchor}
                    options={{
                      displayValue: false,
                      width:1,
                      height:16,
                    }}
                  />
                ) : null}
                {/* </div> */}
              </div>
            </div>
            )
          })}

        
        </div>

      </div>

    </Wrapper>
  );
};

const PrintModalContainer = ({s_printData, set_s_printData, set_s_printModal}) => {

  // console.log('s_printData = ', s_printData)

  const printRef = useRef("");
  const [isPrint, setIsPrint] = useState(true);
  const [s_printObjs, set_s_printObjs] = useState([]);

  const [s_objs, set_s_objs] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => CreatePrintNumObj(printNum),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      size: A4;
      margin-top:50px;
      margin-left: 40px;
      margin-bottom:0px;
      page-break-after: always;
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
    .pointer-box {
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }

  `,
  });

  useEffect(() => {
    const call_printPosition = async () => {
      const res = await printPosition({
        'sopID': s_printData.sopID,
        'version': s_printData.version
      });
      console.log('res = ', res)
      res.status ? set_s_printObjs(res.data) : set_s_printObjs([]);
    };
    call_printPosition();
  }, []);

  useEffect(() => {
    if (!isPrint) {
      setTimeout(() => {
        setIsPrint(true);
      }, [500]);
    }
  }, [isPrint]);

  useEffect(() => {
    const obj = s_printObjs.map((item, index) => {
      return <FormTemplate key={index} data={item} />
    });
    set_s_objs(obj);
  }, [s_printObjs]);

  useEffect(() => {

    return () => {
      set_s_printData({});
    }
  }, []);

  console.log('s_printObjs = ', s_printObjs)

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {s_printObjs.length > 0 && <FormTemplate data={s_printObjs[0]} />}
      </div>
      <div style={{display: 'none'}} >
        <div ref={printRef} >
          {s_objs}
        </div>
      </div>
      <Divider />
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '15px'}}>
        <Button type="primary" size="large" onClick={() => {
          handlePrint();
          set_s_printModal(false);
        }}>
          列印
        </Button>
      </div>
    </>
  )
};

export default PrintModalContainer;