/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,useRef, useContext } from 'react';
import styled from 'styled-components';

import Header from './Header';

import { MySubtitleH4 } from '../../../styled/publicTag';

import { Form, InputNumber, Input, message } from 'antd';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import { getAllSoptb, modifySoptb } from '../../../services/apis/sopAPI';
import { DataContext } from '../index';
import RecModal from './RecModal';
import { BarcodeOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  // 編輯Table用
  .editable-cell {
    position: relative;
  }
  // 編輯Table用
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  // 編輯Table用
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 32px;
    position: absolute;
    bottom: 8px;
    width: 90%;
  }

  .editable-row:hover .antd-table-cell {
    position: relative;
  }

  // 編輯Table用
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      console.log('values = ', values[dataIndex])
      // 用來判斷目前是改哪一筆資料
      const UU_key = `${record.sopID}_${record.version}_${record.sopno}`;
      handleSave(UU_key, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        {dataIndex === 'note' ? (
          <Input 
            ref={inputRef} 
            onPressEnter={() => save(dataIndex)} 
            onBlur={() => save(dataIndex)}
            style={{width: '100%'}}
          />
        ) : (
          <InputNumber 
            ref={inputRef} 
            onPressEnter={() => save(dataIndex)} 
            onBlur={() => save(dataIndex)}
            style={{width: '100%'}}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TB = () => {
  const innerWidth = window.innerWidth;
  const [s_tableData, set_s_tableData] = useState([]);

  const [s_rec, set_s_rec] = useState({});
  const [s_showRecModal, set_s_showRecModal] = useState(false);    // 是否開啟 dialog

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const [scrollY, setScrollY] = useState("");

  const { c_lightData, set_c_lightData } = useContext(DataContext);
   
  // 取得所有投料項目明細
  const call_getAllSoptb = async () => {
    try {
      //console.log(c_lightData)
      const res = await getAllSoptb({ sopID: c_lightData.sopID, version: c_lightData.version, spotID: c_lightData.spotID });
      // console.log('res = ', res)
      if (res.data) {
        // console.log('全部投料項目明細 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } else {
        set_s_tableData([])
      }

    } catch (e) {
      console.log('---------- api getAllSoptb error ----------');
      console.log(e)
    }
  } 
  
  // 編輯表身
  const call_modifySoptb = async (tmpObj) => {
    const res = await modifySoptb(tmpObj);
    if(res.status) {
      message.success(res.msg)
      call_getAllSoptb()
    }else{
      message.error(res.msg)
    } 
  };

  useEffect(() => {
    if (Object.keys(c_lightData).length > 0) {
      call_getAllSoptb()
    } else {
      set_s_tableData([])
    }
  }, [c_lightData, s_showRecModal]);

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  }

  const recClick = (rowData) => {
    set_s_rec(rowData);
    set_s_showRecModal(true);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  // 表身有資料、RWD 時計算高度
  useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if(innerWidth >= 1440) {
      setScrollY(ROWHEIGHT * 6);
    }
    
    // 一般螢幕尺寸
    if(innerWidth >= 1920) {
      setScrollY(ROWHEIGHT * 6);
    }

  }, [innerWidth, c_lightData]);

  const tableColumns = [
    // {
    //   title: "SOP文編",
    //   dataIndex: "sopID",
    //   key: "sopID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    // },
    // {
    //   title: "版次",
    //   dataIndex: "version",
    //   key: "version",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "工位控程",
      dataIndex: "sopno",
      key: "sopno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "組裝序ASSY",
      dataIndex: "assyno",
      key: 'assyno',
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      onCell: record => {
        return {
          style: { backgroundColor: 'rgb(231, 247, 231)' }
        }
      },
    },
    {
      title: "檢測序INSP",
      dataIndex: "inspno",
      key: "inspno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      onCell: record => {
        return {
          style: { backgroundColor: 'rgb(231, 247, 231)' }
        }
      },
    },
    {
      title: "包裝序PACK",
      dataIndex: "packno",
      key: "packno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      onCell: record => {
        return {
          style: { backgroundColor: 'rgb(231, 247, 231)' }
        }
      },
    },
    {
      title: "作業人數",
      dataIndex: "laborno",
      key: "laborno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "人員紀錄",
    //   dataIndex: "laborUUID",
    //   key: "laborUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "設備數",
      dataIndex: "equipno",
      key: "equipno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "設備紀錄",
    //   dataIndex: "equipUUID",
    //   key: "equipUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "投料數",
      dataIndex: "feedno",
      key: "feedno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "投料紀錄",
    //   dataIndex: "feedUUID",
    //   key: "feedUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    {
      title: '掃碼設定',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{fontSize: '24px', cursor: 'pointer'}}>
          <BarcodeOutlined 
            onClick={event => {
              event.stopPropagation();
              recClick(rowData)
            }}
          />
        </div>
      )
    }
  ];

  const handleSave = (UU_key, dataIndex, value) => {
    const data = s_tableData.find(item => `${item.sopID}_${item.version}_${item.sopno}` === UU_key);
    console.log('要傳給後端的資料 = ', {
      ...data,
      [dataIndex]: value
    })
    call_modifySoptb({
      ...data,
      [dataIndex]: value
    });
    // const data = s_tableData.map(item => {
    //   const key = `${item.sopID}_${item.version}_${item.sopno}`;
    //   if(key === UU_key) {
    //     return {
    //       ...item,
    //       [dataIndex]: value
    //     }
    //   }
    //   else {
    //     return item;
    //   }
    // });
    // set_s_tableData(data);
  }

  const columns = tableColumns.map((col) => {
    
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        // style: {
        //   backgroundColor: record?.lifeF === 'T' ? '#e1dbdb' : '',
        //   pointerEvents: record?.lifeF === 'T' ? 'none' : '',
        //   opacity: record?.lifeF === 'T' ? '.5' : '1',
        //   color: record?.lifeF === 'T' ? '#b6b1b1' : record?.confirmqty === 0 ? '#b6b1b1' : '#FF850A'
        // }
      }),
    };
  });

  return (
    <Wrapper>

      <Title title='工位規劃' >
        <Header handleClose={handleClose} />
      </Title>


      <CustomTable
        components={components}
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={columns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        rowClassName="editable-row"
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_showRecModal ? (
        <CustomModal
          width='90%'
          // title='SOP投料全局項目編輯'
          // title="工位掃碼設定"
          footer={null}
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>工位掃碼設定</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>工位控程：{s_rec.sopno}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>組裝序ASSY：{s_rec.assyno}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>檢測序INSP：{s_rec.inspno}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>包裝序PACK：{s_rec.packno}</span>
              </div>
            </div>
          )}
          visible={s_showRecModal}
          onCancel={() => set_s_showRecModal(false)}
        >
          <RecModal
            s_rec={s_rec}
            set_s_showRecModal={set_s_showRecModal}
          />
        </CustomModal>
      ) : null}

    </Wrapper>
  )
};

export default TB;