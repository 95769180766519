import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let timeOut = 5 * 60 * 1000; //設定超時時間: 10分

window.onload = function () {
  window.document.onmousemove = function () {
    lastTime = new Date().getTime(); //更新操作時間
  };
};
function webOutTime() {

  // console.log('window.location.pathname = ', window.location.pathname)

  currentTime = new Date().getTime(); //更新當前時間
  if (currentTime - lastTime > timeOut) {
    //判斷是否超時
    // Modal.confirm({
    //   title: '系統提示',
    //   icon: <ExclamationCircleOutlined style={{color: 'orange'}} />,
    //   content: '請點選確定重新登入網頁',
    //   okText: '確定',
    //   onOk: () => {
    //     window.location.assign(window.location.href);//刷新當前頁
    //   },
    //   cancelText: '取消',
    // });
    // return true
    window.location.assign(window.location.href);//刷新當前頁
  }
}

export default webOutTime;