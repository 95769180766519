import styled from "styled-components";
import { fontSize } from "../../styled/publicTag.js";
import { Table } from "antd";

const StyleTable = styled(Table)`
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }

  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  // 勾勾的顏色
  .successIcon {
    color: ${({ theme }) => theme.Color1};
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.p};
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-left: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-right: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .checkIcon {
    text-align: center;
    font-size: 20px;
  }

  .clickRowStyle {
    background-color: rgba(253, 255, 203, 0.5);
  }

  /* .checkIcon > * {
    color: ${({ theme }) => theme.Color3};
  } */

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${fontSize.text};
    white-space: nowrap;
  }

  .dashboard-font-size {
    font-size: 30px;
  }

  .center {
    text-align: center;
  }

  .icon {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }
  .clickRowStyle {
    background-color: #d5f5f1;
  }

  .clickRowStyle {
    background-color: #f6f8c9;
  }
  tbody .bg-f9e9d7ac {
    background-color: #f9e9d7ac;
  }
  .bg-white {
    background-color: #fff;
  }
  .bg-grey {
    background-color: #d8d8d8;
  }
`;
const CustomTable = ({ columns, dataSource, className, ...props }) => {
  let data = [];
  try {
    if (dataSource.length < 0 || !Array.isArray(dataSource)) {
      data = [];
    } else {
      data = dataSource;
    }
  } catch (e) {
    data = [];
  }

  const newCol = columns.map((x) => {
    return {
      ...x,
      width: x.width ? x.width : null,
      className: x.className ? `${x.className}` : "",
      // className: x.className ? `${x.className} "row-min-width"` : "row-min-width",
      // style: x.width ? "" :
    };
  });

  return (
    <StyleTable
      {...props}
      columns={newCol}
      dataSource={data}
      className={className}
    />
  );
};

export default CustomTable;
