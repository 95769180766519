import React, { useState } from 'react';
import { message } from 'antd';

import HD from './HD';
import TB from './TB';

message.config({ duration: 1.5 });

export const DataContext = React.createContext();

const SOP = () => {

  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData}}>
          <HD />
          {Object.keys(c_lightData).length > 0 ? <TB /> : null}
    </DataContext.Provider>
  )
};

export default SOP;