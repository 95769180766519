import React, { useState, useEffect, useContext, useRef } from 'react';

import { MySubtitleH4 } from '../../../styled/publicTag';

import CustomTable from '../../../components/antdCustom/CustomTable';
import { Drawer, message } from 'antd';

import { getBarcodeWoNTb } from '../../../services/api'
import { DataContext } from '../index';

import { ExclamationCircleOutlined, BarcodeOutlined } from '@ant-design/icons';

import DrawerModal from './DrawerModal';
import LogModal from './LogModal';

const TB = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);

  const [ is_showDraw, set_is_showDraw ] = useState(false);

  const [ is_showLogDraw, set_is_showLogDraw ] = useState(false);

  const [ s_pcid, set_s_pcid ] = useState('');

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 0,
    totalItems: 0,
    pageSize: 10
  });

  const [scrollY, setScrollY] = useState(250)

  const { c_lightData, set_c_lightData } = useContext(DataContext);

  // 取得所有投料項目明細
  const call_getBarcodeWoNTb = async () => {
    try {
      //console.log(c_lightData)
      const res = await getBarcodeWoNTb({ woN: c_lightData.woN });
      console.log('res = ', res)
      if (res.data) {
        // console.log('全部投料項目明細 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData);
        message.success(res.msg)
      } else {
        set_s_tableData([])
        message.error(res.msg)
      }

    } catch (e) {
      console.log('---------- api getAllSoptb error ----------');
      console.log(e)
    }
  } 

   // 第一次渲染取得表身資料
   useEffect(() => {
    call_getBarcodeWoNTb()
  }, [c_lightData]);

  const tableColumns = [
    {
      title: "生產序號",
      dataIndex: "pcid",
      align: "center",
      // width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "出貨序號",
      dataIndex: "ppid",
      align: "center",
    },
    {
      title: '返修紀錄',
      dataIndex: 'log',
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{fontSize: '24px', cursor: 'pointer'}}>
          <ExclamationCircleOutlined 
            onClick={event => {
              event.stopPropagation();
              set_s_pcid(rowData.pcid)
              set_is_showLogDraw(true);
            }}
          />
        </div>
      )
    },
    {
      title: '條碼詳情',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{fontSize: '24px', cursor: 'pointer'}}>
          <BarcodeOutlined 
            onClick={event => {
              event.stopPropagation();
              set_s_pcid(rowData.pcid)
              set_is_showDraw(true);
            }}
          />
        </div>
      )
    }
  ]

  return (
    <>
      <CustomTable
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          // defaultPageSize: 30,
          pageSize: 20,
          pageSizeOptions: [10, 20, 30, 40],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {/* 返修紀錄 */}
      <Drawer
        title="返修紀錄" 
        placement="right" 
        width={1200} 
        onClose={() => {
          set_is_showLogDraw(false)
        }} 
        visible={is_showLogDraw}
      >
        {is_showLogDraw === true ? (
          <LogModal s_pcid={s_pcid} />
        ) : null}
        
      </Drawer>

      {/* 查看更多-抽屜 */}
      <Drawer 
        title="條碼詳情" 
        placement="right" 
        width={1200} 
        onClose={() => {
          // set_s_DrawerData({});
          set_is_showDraw(false)
        }} 
        visible={is_showDraw}
      >
        {is_showDraw === true ? (
          <DrawerModal s_pcid={s_pcid} />
        ) : null}
      </Drawer>
    </>
  )
};

export default TB;