import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Table } from 'antd';

import { fontSize, MySubtitleH4 } from '../../styled/publicTag';
import { Input , Button, message } from 'antd';

import { PlusOutlined, ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { getAllStation, deleteStation, getStation } from '../../services/api';

import ModalContainer from './ModalContainer'

import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

import {
  IconTrash,
  IconPen
} from '../../components/Icon'


message.config({
  duration: 1.5
})

const Station = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_Stationata, set_s_Stationata] = useState({});

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的工站資料
  const createStation = () => {
    set_s_showModal(true);
  }

  // 搜尋工站資料
  const onSearch = (value, event) => {
    
    const call_getStation = async (value) => {
      
      if(value === '') return;
      
      const res = await getStation(value);
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getStation(value);
  }

  //編輯工站資料
  const editPS = rowData => {
    set_s_showModal(true);
    set_s_Stationata(rowData);
  }

  // 刪除工站資料
  const removeStation = rowData => {
    Modal.confirm({
      title: '工站刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的工站無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteStation(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllStation();
      },
      cancelText: '取消',
    });
  }

  // 取得所有工站資料
  const call_getAllStation = async () => {
    try {
      const res = s_searchValue === '' ? await getAllStation() : await getStation(s_searchValue);
      if(res.data) {
        console.log('全部工站資料 = ', res.data)
        set_s_tableData(res.data)
      }
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }
  // 取得所有工站資料
   useEffect(() => { 
    call_getAllStation();
    if(s_showModal === false) {
      set_s_Stationata({})
    }
    console.log('----------call getAll')
  }, [s_showModal]);

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value);
    if(value === '') {
      try {
        const res = await getAllStation();
        if(res.data) {
          console.log('全部工站資料 = ', res.data)
          set_s_tableData(res.data)
        }
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
  }
  
  const tableColumns = [
    {
      title: '工站ID',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      // width: 100
    },
    {
      title: '工站名稱',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
    },
    {
      title: '設定短單',
      dataIndex: 'set0wpc',
      key: 'set0wpc',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set0wpc;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定工段',
      dataIndex: 'set1gpc',
      key: 'set1gpc',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set1gpc;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定模具同步',
      dataIndex: 'set2mold',
      key: 'set2mold',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set2mold;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定工時',
      dataIndex: 'set3whm',
      key: 'set3whm',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set3whm;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定原料投料',
      dataIndex: 'set4feedlot',
      key: 'set4feedlot',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set4feedlot;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    // {
    //   title: '批號管理',
    //   dataIndex: 'setblotlife',  // ??????????
    //   key: 'bbb',
    //   // width: 100
    // },
    {
      title: '設定條碼管理',
      dataIndex: 'set5pid',
      key: 'set5pid',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set5pid;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定輔線',
      dataIndex: 'set6alm',
      key: 'set6alm',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set6alm;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定淨重劑量',
      dataIndex: 'set7netwt',
      key: 'set7netwt',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set7netwt;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }

    },
    {
      title: '設定SOP監控',
      dataIndex: 'set8sop',
      key: 'set8sop',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set8sop;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定SIP監控',
      dataIndex: 'set9sip',
      key: 'set9sip',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set9sip;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定配方',
      dataIndex: 'setarpm',
      key: 'setarpm',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.setarpm;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定物控作業',
      dataIndex: 'MC',
      key: 'MC',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.MC;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定質控作業',
      dataIndex: 'QC',
      key: 'QC',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.QC;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定生產控制',
      dataIndex: 'WOC',
      key: 'WOC',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.WOC;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定工站報工',
      dataIndex: 'SFC',
      key: 'SFC',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.SFC;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定委外報工',
      dataIndex: 'OUT',
      key: 'OUT',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.OUT;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '設定生產查詢',
      dataIndex: 'PDT',
      key: 'PDT',
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.PDT;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'space-around'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editPS(rowData)} />
            <IconTrash className='icon' onClick={() => removeStation(rowData)} />
          </div>
        </div>
      )
    },
  ]
  return (
    <>
      <Title 
        title='工站資料管理'
        placeholder='請輸入欲查詢工站資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStation}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? ( 
        <CustomModal 
          width='60%' 
          title={Object.keys(s_Stationata).length > 0 ? "修改工站資料管理" : "新建工站資料管理"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            //set_s_Stationata({})
            //call_getAllStation()
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_Stationata={s_Stationata} />
        </CustomModal>
      ) : null}
      
    </>
  )
}
export default Station;