import React, { useState, useEffect } from 'react';

import { MySubtitleH4 } from '../../styled/publicTag.js';
import { getAllStaff, getFunctions, getPushlvl, getStaff, freezeStaff } from '../../services/api.js';

import { ExclamationCircleOutlined } from '@ant-design/icons'

import {
  IconLock,
  IconUnlock,
  IconPen
} from '../../components/Icon'

import { Modal, message } from 'antd';

import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';


//component
import ModalContainer from './ModalContainer';
import FreezeContainer from './ModalContainer/FreezeContainer';



const Users = () => {

  const innerWidth = window.innerWidth;

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_showFreezeModal, set_s_showFreezeModal] = useState(false); // 是否開啟凍結員工基本資料的 dialog

  const [s_freezePersion, set_s_freezePersion] = useState({}) // 要被設定凍結的員工
  
  const [s_tableData, set_s_tableData] = useState({});       // 全部員工基本資料

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_staffIDs, set_s_staffIDs] = useState([]); // 全部員工ID

  const [s_staffData, set_s_staffData] = useState({}); // 要被修改的員工基本資料

  const [s_Functions, set_s_Functions] = useState([]); // 組織功能列表

  const [s_Pushlvl, set_s_Pushlvl] = useState([]);    // 推播層級列表

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 取得全部員工基本資料(api)
  const call_getAllStaff = async () => {
    try {
      setTableLoading(true);
      //取得所有員工資料
      const res = s_searchValue === '' ? await getAllStaff() : await getStaff(s_searchValue);
      //console.log(res)
      if(res.data) {
       // console.log('全部員工基本資料 = ', res.data)
        //res.data.forEach(item => console.log('staffID = ', item.staffID, '  quitT = ', item.quitT))
        const staffIDs = res.data.filter(item => item.staffID);
        set_s_tableData({
          'data': res.data,
          'staffIDs': staffIDs
        })
      }
      setTableLoading(false);
    } catch (e) {
      console.log("全部員工基本資料-資料獲取失敗", e);
    }
  };
  //取得全部員工基本資料，每次關閉 dialog 都重抓
  useEffect(() => {
    call_getAllStaff(); 
    //call_fake_getAllStaff()
    if(s_showModal === false) {
      set_s_staffData({});
    }
  }, [s_showModal, s_showFreezeModal]);

  // 取得組織功能、推播層級
  useEffect(() => {
    const call_getData = async () => {
      try {
        //取得組織功能的 Options
        const Functions_res = await getFunctions();
        console.log('Functions_res = ', Functions_res)
        //取得推播層級的 Options
        const Pushlvl_res = await getPushlvl();
        console.log('Pushlvl_res = ', Pushlvl_res)
        Functions_res.status ? set_s_Functions(Functions_res.data) : set_s_Functions([]);
        Pushlvl_res.status ? set_s_Pushlvl(Pushlvl_res.data) : set_s_Pushlvl([]);
      } catch (e) {
        console.log("取得組織功能、推播層級-資料獲取失敗", e);
      }
    }
    call_getData()
    
    const call_fake_getData = () => {
      const fake_Function = [
        {
            "funcode": "M1",
            "funNM": "生管",
            "note": ""
        },
        {
            "funcode": "M2",
            "funNM": "生技",
            "note": ""
        },
        {
            "funcode": "M3",
            "funNM": "倉管",
            "note": ""
        },
        {
            "funcode": "M4",
            "funNM": "生產",
            "note": ""
        },
        {
            "funcode": "Q0",
            "funNM": "品保(全)",
            "note": ""
        },
        {
            "funcode": "O0",
            "funNM": "生管(全)",
            "note": ""
        },
        {
            "funcode": "O1",
            "funNM": "庫管(全)",
            "note": ""
        },
        {
            "funcode": "P0",
            "funNM": "生產",
            "note": ""
        },
        {
            "funcode": "T0",
            "funNM": "技術",
            "note": ""
        },
        {
            "funcode": "R0",
            "funNM": "研發",
            "note": ""
        },
        {
            "funcode": "S0",
            "funNM": "業務",
            "note": ""
        },
        {
            "funcode": "F0",
            "funNM": "財會",
            "note": ""
        }
      ]
      const fake_Pushlvl = [
        {
            "lvlcode": 1,
            "lvlNM": "班組",
            "note": ""
        },
        {
            "lvlcode": 2,
            "lvlNM": "課級",
            "note": ""
        },
        {
            "lvlcode": 3,
            "lvlNM": "理級",
            "note": ""
        },
        {
            "lvlcode": 4,
            "lvlNM": "總級",
            "note": ""
        },
        {
            "lvlcode": 0,
            "lvlNM": "全員",
            "note": ""
        }
      ]
      set_s_Functions(fake_Function);
      set_s_Pushlvl(fake_Pushlvl);
    }
    //call_fake_getData()
  }, []);

  // 建立新的員工資料
  const createStaff = () => {
    set_s_showModal(true);
  }

  // 修改員工基本資料
  const editStaff = staffData => {
    set_s_showModal(true);
    set_s_staffData(staffData);
  }

  // 凍結員工基本資料
  const handleFreezeStaff = rowData => {
    set_s_showFreezeModal(true);
    set_s_freezePersion(rowData)
  }

  //解除凍結員工基本資料
  const handleUnFreezeStaff = rowData => {
    Modal.confirm({
      title: '用戶解除凍結確認',
      icon: <ExclamationCircleOutlined style={{color: 'orange'}} />,
      content: '請確認您希望解除凍結此用戶',
      okText: '解除凍結',
      onOk: async () => {
        const tmpObj = {
          'staffID': rowData.staffID,
          'quitT': ''
        }
        const res = await freezeStaff(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要刷新資料
        call_getAllStaff();
      },
      cancelText: '取消',
    });
  }

  //table 要有哪些欄位
  const tableColumns = [
    {
      title: '員工工號',
      dataIndex: 'staffID',
      key: 'staffID',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
      //...getColumnSearchProps('staffID'),
    },
    {
      title: '員工姓名',
      dataIndex: 'peopleNM',
      key: 'peopleNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
      //...getColumnSearchProps('peopleNM'),
    },
    {
      title: '郵址',
      dataIndex: 'mailaddr',
      key: 'mailaddr',
      align: 'center',
      //width: 150
      //...getColumnSearchProps('mailaddr'),
    },
    {
      title: '卡勤識別碼',
      dataIndex: 'cardID',
      key: 'cardID',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${120*1.6}px`
      //...getColumnSearchProps('cardID'),
    },
    {
      title: '電話',
      dataIndex: 'tel',
      key:'tel',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
      //...getColumnSearchProps('tel'),
    },
    {
      title: '聯絡人',
      dataIndex: 'proxyNM',
      key:'proxyNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
      //...getColumnSearchProps('proxyNM'),
    },
    {
      title: '聯絡人電話',
      dataIndex: 'proxytel',
      key: 'proxytel',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${120*1.6}px`
      // ellipsis: true
      //...getColumnSearchProps('proxytel'),
    },
    {
      title: '組織功能',
      dataIndex: 'funNM',
      key: 'funNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`,
      render: (text, rowData, index) => {
        
        const array = rowData.function && rowData.function.map(item => {
          const obj = s_Functions.find(s_Function => s_Function.funcode === item)
          return obj && obj.funNM
        });
        //console.log('array = ', array)
        //console.log('s_Functions = ', s_Functions)
        return array && array.join(',')
      }
      // dataIndex: 'function',
      // key: 'function',  
      //...getColumnSearchProps('function'),
      // render: (text, rowData, index) => {
      //   const obj = s_Functions && s_Functions.find(item => item.funcode === rowData.function)
      //   return obj ? obj.funNM : ''
      // },
      
    },
    {
      title: '推播層級',
      dataIndex: 'lvlNM',
      key: 'lvlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80*1.6}px`
      // dataIndex: 'level',
      // key: 'level',
      // ...getColumnSearchProps('level'),
      // render: (text, rowData, index) => {
      //   const obj = s_Pushlvl && s_Pushlvl.find(item => item.lvlcode === rowData.level)
      //   return obj ? obj.lvlNM : ''
      // },
    },
    {
      title: '年資(月)',
      dataIndex: 'senioritiy',
      key: 'senioritiy',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80*1.6}px`
      // dataIndex: 'level',
      // key: 'level',
      // ...getColumnSearchProps('level'),
      // render: (text, rowData, index) => {
      //   const obj = s_Pushlvl && s_Pushlvl.find(item => item.lvlcode === rowData.level)
      //   return obj ? obj.lvlNM : ''
      // },
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      // className: 'center',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50*1.6}px`,
      render: (text, rowData, index) => {
        return {
          // props: {
          //   style: { display: 'flex' }
          // },
          children: (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <IconPen className='icon' width={30} height={30} onClick={() => editStaff(rowData)} />
              {rowData.quitTString !== '' ? (
                <IconLock className='icon' width={30} height={30} onClick={() => handleUnFreezeStaff(rowData)} /> 
              ) : (
                <IconUnlock className='icon' width={30} height={30} onClick={() => handleFreezeStaff(rowData)} /> 
              )}
             
            </div>
          )
        }
      }
    }
  ];

  const handleChange = async value => {
    set_s_searchValue(value);
    console.log('value = ', value)
    if(value === '') {
      console.log('here')
      try {
        setTableLoading(true);
        //取得所有員工資料
        const res = await getAllStaff();
        //console.log(res)
        if(res.data) {
         // console.log('全部員工基本資料 = ', res.data)
          //res.data.forEach(item => console.log('staffID = ', item.staffID, '  quitT = ', item.quitT))
          const staffIDs = res.data.filter(item => item.staffID);
          set_s_tableData({
            'data': res.data,
            'staffIDs': staffIDs
          })
        }
        setTableLoading(false);
      } catch (e) {
        console.log("全部員工基本資料-資料獲取失敗", e);
      }
    }
  }

  // 搜尋員工基本資料
  const onSearch = (value, event) => {
    
    const call_getStaff = async () => {
      
      if(s_searchValue === '') return;
      // api 有問題，待串接
      //return
      const res = await getStaff(s_searchValue);
      
      if(res.status) {
        console.log(res.data)
        set_s_tableData({
          ...s_tableData,
          'data': res.data
        });
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getStaff(s_searchValue);
  }

  return (
    <>
      <Title 
        title='員工基本資料'
        placeholder='請輸入欲查詢員工資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStaff}
      />
      {/* 下面這樣寫是因為要渲染'組織功能'、'推播層級'的中文字 */}
      {s_Functions.length > 0 ? (
        <CustomTable 
          //bordered={true}
          loading={tableLoading}
          scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 }}
          columns={tableColumns}
          dataSource={s_tableData && s_tableData.data && s_tableData.data.map((item, index) => {
            return  { ...item, key: index }
          })}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            // current: abcBoardConfig.page,
            showSizeChanger: true,
            pageSize: pageInfo.pageSize,
            pageSizeOptions: ["10", "20", "30"],
            onChange: (current, size) => {
              setPageInfo({
                ...pageInfo,
                pageSize: size
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      ) : null}
      {/* 上面這樣寫是因為要渲染'組織功能'、'推播層級'的中文字 */}
      {s_showModal ? (
        <CustomModal 
          width='60%' 
          title={Object.keys(s_staffData).length === 0 ? "新建員工基本資料" : "修改員工基本資料"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => set_s_staffData({})}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_staffData={s_staffData} />
        </CustomModal>
      ) : null}
      

      {/* 凍結員工基本資料 */}
      {s_showFreezeModal ? (
        <CustomModal
          width='60%'
          title="凍結員工基本資料"
          visible={s_showFreezeModal} 
          onCancel={() => {
            set_s_showFreezeModal(false)
          }}
          footer={null}
        >
          <FreezeContainer 
            set_s_showFreezeModal={set_s_showFreezeModal} 
            s_freezePersion={s_freezePersion}
            set_s_freezePersion={set_s_freezePersion}
          />
        </CustomModal>
      ) : null}
    </>
  )
};

export default Users;