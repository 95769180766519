import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment'
import Workbook from 'react-excel-workbook';
import { Form, Row, Col, Input, DatePicker, Button, Select, message } from 'antd';
import { IconExcel } from '../../../../components/Icon/index';

message.config({ duration: 1.5 });

const StyledForm = styled(Form)`
  
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({s_tableData, set_is_showModal}) => {
  
  const [ExcelColumns, set_ExcelColumns] = useState([]);

  const [s_Excel, set_s_Excel] = useState([]);

  const [form] = Form.useForm();

  // 取得 Excel 表頭
  useEffect(() => {
    
    const HDArray = [
      {
        title: 'Model',
        key: 'Model'
      },
      {
        title: 'Item_No',
        key: 'Item_No'
      },
      {
        title: 'DFI_PN',
        key: 'DFI_PN'
      },
      {
        title: 'Version',
        key: 'Version'
      },
      {
        title: 'SSN',
        key: 'SSN'
      },
      {
        title: 'MSN',
        key: 'MSN'
      },
      {
        title: 'INV',
        key: 'INV'
      },
      {
        title: 'Shipment_Date',
        key: 'Shipment_Date'
      },
      {
        title: 'Cust_Po_Number',
        key: 'Cust_Po_Number'
      },
      {
        title: 'Pallet_No',
        key: 'Pallet_No'
      },
      {
        title: 'Order_No',
        key: 'Order_No'
      },
    ]

    const MBArray = [
      {
        title: 'MB_MODEL1',
        key: 'MB_MODEL1'
      },
      {
        title: 'MB_SN1',
        key: 'MB_SN1'
      },
      {
        title: 'MB_PN1',
        key: 'MB_PN1'
      },
      {
        title: 'MB_REV1',
        key: 'MB_REV1'
      },
      {
        title: 'MB_MODEL2',
        key: 'MB_MODEL2'
      },
      {
        title: 'MB_SN2',
        key: 'MB_SN2'
      },
      {
        title: 'MB_PN2',
        key: 'MB_PN2'
      },
      {
        title: 'MB_REV2',
        key: 'MB_REV2'
      },
      {
        title: 'MB_MODEL3',
        key: 'MB_MODEL3'
      },
      {
        title: 'MB_SN3',
        key: 'MB_SN3'
      },
      {
        title: 'MB_PN3',
        key: 'MB_PN3'
      },
      {
        title: 'MB_REV3',
        key: 'MB_REV3'
      },
      {
        title: 'MB_MODEL4',
        key: 'MB_MODEL4'
      },
      {
        title: 'MB_SN4',
        key: 'MB_SN4'
      },
      {
        title: 'MB_PN4',
        key: 'MB_PN4'
      },
      {
        title: 'MB_REV4',
        key: 'MB_REV4'
      },
      {
        title: 'MB_MODEL5',
        key: 'MB_MODEL5'
      },
      {
        title: 'MB_SN5',
        key: 'MB_SN5'
      },
      {
        title: 'MB_PN5',
        key: 'MB_PN5'
      },
      {
        title: 'MB_REV5',
        key: 'MB_REV5'
      },
    ]
    
    const dataArray = [...Array(30)].map((item, index) => {
      return [
        {
          title: `KP_TYPE${index+1}`,
          key: `KP_TYPE${index+1}`,
        },
        {
          title: `KP_SN${index+1}`,
          key: `KP_SN${index+1}`,
        },
        {
          title: `KP_PN${index+1}`,
          key:`KP_PN${index+1}`,
        }
      ]
    });

    const tmpArray = [].concat.apply([], HDArray.concat(MBArray).concat(dataArray));

    set_ExcelColumns(tmpArray)
  }, []);

  useEffect(() => {
    form.resetFields()
  }, [form]);

  const onSubmit = async values => {
    for(const item of s_tableData) {
      item.KP = item.KP.filter(x => x.PN !== 'ASSY-END' && x.PN !== 'TESTING-CHECK' && x.PN !== 'DETERMINE{BURN-IN-CHECK}' && x.PN !== 'DETERMINE{FUNCTION-CHECK}' && x.PN !== 'DETERMINE{PRETESTING-CHECK}' && x.PN !== 'DONE' && x.PN !== 'START')
      .map(x => {
        let index = x.PN.lastIndexOf('{')

        if(index === -1) return x

        // else
        return {...x, PN:x.PN.slice(0,index)}
      })
    }

    const tmpArray = s_tableData.map((item, index) => {
      const dataArray = [...Array(30)].map((item1, index1) => {
        return {
          [`KP_TYPE${index1+1}`]: item.KP[index1] && values ? values.Model : "",
          [`KP_SN${index1+1}`]: item.KP[index1] ? item.KP[index1].SN : "",
          [`KP_PN${index1+1}`]: item.KP[index1] ? item.KP[index1].PN : "",
        }

        // if(Object.values(values).includes(item.KP[index1]?.PN)) {
        //   return {
        //     [`KP_${index1+1}_SN`]: "",
        //     [`KP_${index1+1}_TYPE`]: "",
        //     [`KP_${index1+1}_PN`]: "",
        //   }
        // } else {
        //   return {
        //     [`KP_${index1+1}_SN`]: item.KP[index1] ? item.KP[index1].SN : "",
        //     [`KP_${index1+1}_TYPE`]: item.KP[index1] ? item.KP[index1].TYPE : "",
        //     [`KP_${index1+1}_PN`]: item.KP[index1] ? item.KP[index1].PN : "",
        //   }
        // }

      })

      const bbb = {}

      dataArray.forEach(element => {
        Object.keys(element).forEach((qqq) => {
          bbb[qqq] = element[qqq]
        });
      });

      // console.log('bbb = ', bbb)

      return {
        'Model': values.Model,
        'SSN': item.SSN,
        'MSN': item.MSN,
        'DFI_PN': item.PN,
        'Pallet_No': item.Pallet_No,
        ...values,
        'Shipment_Date': moment(values.Shipment_Date).format("YYYYMMDD"),
        ...bbb,
        'MB_SN1': values.MB_PN1 !== '' && item.KP.find(KP_item => KP_item.PN === values.MB_PN1)?.SN,
        'MB_MODEL1': values.MB_PN1 ? values.Model : '',
        
        'MB_SN2': values.MB_PN2 !== '' && item.KP.find(KP_item => KP_item.PN === values.MB_PN2)?.SN,
        'MB_MODEL2': values.MB_PN2 ? values.Model : '',

        'MB_SN3': values.MB_PN3 !== '' && item.KP.find(KP_item => KP_item.PN === values.MB_PN3)?.SN,
        'MB_MODEL3': values.MB_PN3 ? values.Model : '',

        'MB_SN4': values.MB_PN4 !== '' && item.KP.find(KP_item => KP_item.PN === values.MB_PN4)?.SN,
        'MB_MODEL4': values.MB_PN4 ? values.Model : '',

        'MB_SN5': values.MB_PN5 !== '' && item.KP.find(KP_item => KP_item.PN === values.MB_PN5)?.SN,
        'MB_MODEL5': values.MB_PN5 ? values.Model : '',
      }
    })

    console.log('tmpArray = ', tmpArray)
    console.log('values = ', values)
    
    set_s_Excel(tmpArray);

    const btn = document.querySelector('.excelBtn');
    btn.click();

    set_is_showModal(false)
  }

  const handleCancel = () => {
    set_is_showModal(false)
  }

  return (
    <Form.Provider
      onFormChange={(formName, info) => {
        console.log(info)
        const { changedFields } = info;
        // if(changedFields[0] && changedFields[0].name[0] === 'MB_PN1') {
        //   form.setFieldsValue({
        //     'MB_MODEL1': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).TYPE,
        //     'MB_SN1': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).SN,
        //   });
        // }
        // if(changedFields[0] && changedFields[0].name[0] === 'MB_PN2') {
        //   form.setFieldsValue({
        //     'MB_MODEL2': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).TYPE,
        //     'MB_SN2': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).SN,
        //   });
        // }
        // if(changedFields[0] && changedFields[0].name[0] === 'MB_PN3') {
        //   form.setFieldsValue({
        //     'MB_MODEL3': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).TYPE,
        //     'MB_SN3': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).SN,
        //   });
        // }
        // if(changedFields[0] && changedFields[0].name[0] === 'MB_PN4') {
        //   form.setFieldsValue({
        //     'MB_MODEL4': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).TYPE,
        //     'MB_SN4': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).SN,
        //   });
        // }
        // if(changedFields[0] && changedFields[0].name[0] === 'MB_PN5') {
        //   form.setFieldsValue({
        //     'MB_MODEL5': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).TYPE,
        //     'MB_SN5': s_tableData[0].KP.find(item => item.PN === changedFields[0].value).SN,
        //   });
        // }
      }}
    >
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          // Version: '1',
          // INV: '1',
          // Cust_Po_Number: '1',
          // date: moment('2022-05-04'),
          // Item_No: '1',
          // MB_PN1: '1',
          // MB_REV1: '1',
          // MB_PN2: '1',
          // MB_REV2: '1',
          // MB_PN3: '1',
          // MB_REV3: '1',
          // MB_PN4: '1',
          // MB_REV4: '1',
          // MB_PN5: '1',
          // MB_REV5: '1',
        }}
      >

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>系統版本(Version)</label>}
              name="Version"
            >
              <Input name='Version' placeholder="請填寫系統版本" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>發票號碼(INV)</label>}
              name="INV"
            >
              <Input name='INV' placeholder="請填寫INV" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>客戶PO編號(Cust_Po_Number)</label>}
              name="Cust_Po_Number"
            >
              <Input name='Cust_Po_Number' placeholder="請填寫客戶訂單" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>出貨日期(Shipment_Date)</label>}
              name="Shipment_Date"
            >
              <DatePicker placeholder='請選擇入職日期' format='YYYYMMDD' style={{width: '100%'}} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號號碼(Item_No)</label>}
              name="Item_No"
            >
              <Input name='Item_No' placeholder="請填寫料號號碼" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>機種名稱(Model)</label>}
              name="Model"
            >
              <Input name='Model' placeholder="請填寫機種名稱" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>訂購單編號(Order_No)</label>}
              name="Order_No"
            >
              <Input name='Order_No' placeholder="請填寫訂購單編號" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={24}>
            主板：
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號(MB_PN)</label>}
              name="MB_PN1"
            >
              {/* <Input name='MB_PN1' placeholder="請填寫料號" /> */}
              <Select placeholder="選擇料號">
                {s_tableData[0]?.KP?.length > 0 && s_tableData[0].KP.map(item => {
                  return <Select.Option key={item.PN} value={item.PN}>{item.PN}</Select.Option>
                })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>版號(MB_REV)</label>}
              name="MB_REV1"
            >
              <Input name='MB_REV1' placeholder="請填寫版號" />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_MODEL1"
            >
              <Input name='MB_MODEL1' />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_SN1"
            >
              <Input name='MB_SN1' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號(MB_PN)</label>}
              name="MB_PN2"
            >
              {/* <Input name='MB_PN2' placeholder="請填寫料號" /> */}
              <Select placeholder="選擇料號">
                {s_tableData[0]?.KP?.length > 0 && s_tableData[0].KP.map(item => {
                  return <Select.Option key={item.PN} value={item.PN}>{item.PN}</Select.Option>
                })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>版號(MB_REV)</label>}
              name="MB_REV2"
            >
              <Input name='MB_REV2' placeholder="請填寫版號" />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_MODEL2"
            >
              <Input name='MB_MODEL2' />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_SN2"
            >
              <Input name='MB_SN2' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號(MB_PN)</label>}
              name="MB_PN3"
            >
              {/* <Input name='MB_PN3' placeholder="請填寫料號" /> */}
              <Select placeholder="選擇料號">
                {s_tableData[0]?.KP?.length > 0 && s_tableData[0].KP.map(item => {
                  return <Select.Option key={item.PN} value={item.PN}>{item.PN}</Select.Option>
                })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>版號(MB_REV)</label>}
              name="MB_REV3"
            >
              <Input name='MB_REV3' placeholder="請填寫版號" />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_MODEL3"
            >
              <Input name='MB_MODEL3' />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_SN3"
            >
              <Input name='MB_SN3' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號(MB_PN)</label>}
              name="MB_PN4"
            >
              {/* <Input name='MB_PN4' placeholder="請填寫料號" /> */}
              <Select placeholder="選擇料號">
                {s_tableData[0]?.KP?.length > 0 && s_tableData[0].KP.map(item => {
                  return <Select.Option key={item.PN} value={item.PN}>{item.PN}</Select.Option>
                })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>版號(MB_REV)</label>}
              name="MB_REV4"
            >
              <Input name='MB_REV4' placeholder="請填寫版號" />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_MODEL4"
            >
              <Input name='MB_MODEL4' />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_SN4"
            >
              <Input name='MB_SN4' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號(MB_PN)</label>}
              name="MB_PN5"
            >
              {/* <Input name='MB_PN5' placeholder="請填寫料號" /> */}
              <Select placeholder="選擇料號">
                {s_tableData[0]?.KP?.length > 0 && s_tableData[0].KP.map(item => {
                  return <Select.Option key={item.PN} value={item.PN}>{item.PN}</Select.Option>
                })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>版號(MB_REV)</label>}
              name="MB_REV5"
            >
              <Input name='MB_REV5' placeholder="請填寫版號" />
            </Form.Item>
          </Col>

          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_MODEL5"
            >
              <Input name='MB_MODEL5' />
            </Form.Item>
          </Col>
          <Col span={12} style={{display: 'none'}}>
            <Form.Item 
              name="MB_SN5"
            >
              <Input name='MB_SN5' />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            
          </Col>
          <Col offset={8} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                  確定
                </Button>
                <Workbook 
                  filename={`生產條碼查詢.xlsx`} 
                  element={<div className='excelBtn'></div>}
                >
                  <Workbook.Sheet data={s_Excel} name="Sheet A">
                    {ExcelColumns.map((item, index) => {
                      return(
                        <Workbook.Column key={index} label={item.title} value={item.key} />
                      )
                    })}
                  </Workbook.Sheet>
                </Workbook>
              </Form.Item>
            </div>
          </Col>
          
          </Row>

      </StyledForm>
    </Form.Provider>
  )
};

export default ModalContainer;